import React from "react";
// import { Link, graphql } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import "../css/plans.css";

class ContactSuccessPage extends React.Component {
  render(){

    return (
      <Layout>
        <SEO title="Contact Form Submitted" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4">Success</h1>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-8">

              <div class="alert alert-success">
                <strong>Message Sent!</strong> We will get back with you shortly.
              </div>

            </div>
          </div>

        </div>

      </Layout>
    )
  }
};

export default ContactSuccessPage;
